import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { searchProduct } from "../../helpers/fetcher";
import { Returntext } from "../../helpers/helpers";
import imgOther from "../../assets/images/otherImage.png";

interface SearchProps {
  lang?: string;
  contentData?: any;
}

const Search: React.FC<SearchProps> = ({ lang, contentData }) => {
  const [searchValue, setSearchValue] = useState("");
  const [productData, setProductData] = useState([]);
  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      navigate(`productsSearch/${searchValue}`);
      setSearchValue(""); // Clear the search value after redirection
    }
  };

  const fetchData = useCallback(async () => {
    if (searchValue.trim() === "") {
      setProductData([]);
      return;
    }
    try {
      const products = await searchProduct(1, 5, searchValue);
      setProductData(products?.items || []);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setProductData([]);
    }
  }, [searchValue]);

  useEffect(() => {
    fetchData();
  }, [searchValue, fetchData]);

  const handleProductClick = (code: string, id: number) => {
    if (!code || !id) {
      console.error("Missing data for navigation");
      return;
    }
    navigate(`/product/${code}/${id.toString()}`);
    setSearchValue("");
  };

  return (
    <div className="w-[90%] relative">
      <label className="input input-bordered flex items-center gap-2">
        <input
          type="text"
          className="grow nav_font_ka text-[15px]"
          placeholder={Returntext(contentData, "search_txt", lang)}
          value={searchValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          className="w-4 h-4 opacity-70"
        >
          <path
            fillRule="evenodd"
            d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
            clipRule="evenodd"
          />
        </svg>
      </label>
      {searchValue && (
        <div className="absolute w-full bg-white shadow-lg rounded-lg p-4 mt-2">
          {productData.length > 0 ? (
            <>
              {productData.map((item: any, index) => (
                <div
                  key={index}
                  className="flex items-center justify-start gap-4 p-3 rounded hover:bg-gray-100 transition duration-200 cursor-pointer"
                  onClick={() => handleProductClick(item?.code, item?.id)}
                >
                  <img
                    src={`https://montage.ge/img/${item?.photo}` || imgOther}
                    alt={item?.code}
                    className="w-12 h-12 rounded-lg object-cover border border-gray-200"
                    onError={(e) => {
                      e.currentTarget.src = imgOther;
                    }}
                  />
                  <div className="text-left">
                    <div className="text-gray-800 font-medium text-base truncate max-w-xs">
                      {lang === "en" ? item?.name_en : item?.name_ka}
                    </div>
                    <div className="text-gray-600 text-sm font-semibold mt-1">
                      {lang === "en" ? "Code: " : "კოდი: "} {item?.code}
                    </div>
                  </div>
                </div>
              ))}
              <div
                className="text-center mt-4"
                onClick={() => {
                  navigate(`productsSearch/${searchValue}`);
                  setSearchValue(""); // Clear the search value after redirection
                }}
              >
                <button className="bg-[#39B085] text-white font-medium rounded-lg px-5 py-2 hover:bg-[#2f8a6e] transition w-[100%]">
                  {Returntext(contentData, "our_work_see_more", lang)}
                </button>
              </div>
            </>
          ) : (
            <div className="text-gray-500">
              {lang === "en" ? "Nothing found" : "არ მოიძებნა"}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Search;
