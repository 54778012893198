export const API_BASE_URL = "https://m.montage.ge";
// export const API_BASE_URL = "http://localhost:4001";

export async function fetchNavbarData() {
  try {
    const response = await fetch(`${API_BASE_URL}/navbar`);
    if (!response.ok) {
      throw new Error(`Failed to fetch navbar data: ${response.statusText}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching navbar data:", error);
    throw error;
  }
}
export async function fetchNavbarDataById(id: string) {
  try {
    const response = await fetch(`${API_BASE_URL}/navbar/findWithID/${id}`);
    if (!response.ok) {
      throw new Error(`Failed to fetch navbar data: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching navbar data:", error);
    throw error;
  }
}


export async function fetchProductById(id: number) {
  try {
    const response = await fetch(`${API_BASE_URL}/products/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch product: ${response.statusText}`);
    }

    const product = await response.json();
    return product;
  } catch (error) {
    console.error("Error fetching product by ID:", error);
    throw error;
  }
}

export async function fetchProducts(
  menuId: string,
  page: number,
  limit: number,
  selectedFilters: { [key: string]: string }
): Promise<any> {
  try {
    const filterQueryString = Object.keys(selectedFilters)
      .filter((key) => selectedFilters[key])
      .map((key) => `${key}=${selectedFilters[key]}`)
      .join("&");

    const response = await fetch(
      `${API_BASE_URL}/products?menu_id=${menuId}&page=${page}&limit=${limit}&${filterQueryString}`
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch product data: ${response.statusText}`);
    }
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching product data:", error);
    throw error;
  }
}

export async function fetchFilters(
  menuId: string,
  selectedFilters: Record<string, string> = {}
) {
  try {
    const response = await fetch(
      `${API_BASE_URL}/products/getFilterTypes/${menuId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedFilters),
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch filter data: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching filter data:", error);
    throw error;
  }
}

export async function fetchContent() {
  try {
    const response = await fetch(`${API_BASE_URL}/content`);
    if (!response.ok) {
      throw new Error(`Failed to fetch product data: ${response.statusText}`);
    }
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching product data:", error);
    throw error;
  }
}

export async function fetchPartners() {
  try {
    const response = await fetch(`${API_BASE_URL}/partners`);
    if (!response.ok) {
      throw new Error(`Failed to fetch product data: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching product data:", error);
    throw error;
  }
}

export async function searchProduct(page: number, limit: number, text: string) {
  try {
    const response = await fetch(
      `${API_BASE_URL}/products/search?&page=${page}&limit=${limit}&&name=${text}`
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch product data: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching product data:", error);
    throw error;
  }
}

export async function fetchProjects() {
  try {
    const response = await fetch(`${API_BASE_URL}/projects?cat_name`);
    if (!response.ok) {
      throw new Error(`Failed to fetch product data: ${response.statusText}`);
    }

    const contentType = response.headers.get("content-type");
    if (contentType && !contentType.includes("application/json")) {
      throw new Error(`Unexpected content type: ${contentType}`);
    }

    const textData = await response.text();

    if (!textData) {
      throw new Error("Empty response data");
    }

    // Parse the JSON data
    const data = JSON.parse(textData);
    return data;
  } catch (error) {
    console.error("Error fetching product data:", error);
    throw error;
  }
}
