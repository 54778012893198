import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { searchProduct } from "../helpers/fetcher"; // Adjusted imports as needed
import { TextGenerateEffect } from "../components/textGenerateEffect/TextGenerateEffect";
import {
  CardBody,
  CardContainer,
  CardItem,
} from "../components/animatedCard/AnimatedCard";
import { CircularPagination } from "../components/circularPagination/circularPagination";
import { Returntext, useContent, useLanguages } from "../helpers/contLang";
import notFoundIcon from "../assets/images/noItemFound.jpg";
import imgOther from "../assets/images/otherImage.png";

export default function ProductsSearch() {
  const { name } = useParams<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [productData, setProductData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [showNotFound, setShowNotFound] = useState(false);
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  // const [productImages, setProductImages] = useState<{ [key: string]: string }>(
  //   {}
  // );

  // const getProductImagePath = async (
  //   code: string | undefined
  // ): Promise<string> => {
  //   if (!code) return imgOther;

  //   const formattedCodeHyphen = code
  //     .trim()
  //     .replace(/\s+/g, "")
  //     .replace(/AB(?=-)/, "ABB");

  //   const formattedCodeUnderscore = formattedCodeHyphen.replace(/-/g, "_");

  //   const imagePaths = [
  //     `https://montage.ge/images/prod/${formattedCodeHyphen}.jpg`,
  //     `https://montage.ge/images/prod/${formattedCodeHyphen}.png`,
  //     `https://montage.ge/images/prod/${formattedCodeUnderscore}.jpg`,
  //     `https://montage.ge/images/prod/${formattedCodeUnderscore}.png`,
  //   ];

  //   const checkImage = (path: string): Promise<boolean> => {
  //     return new Promise((resolve) => {
  //       const img = new Image();
  //       img.src = path;
  //       img.onload = () => resolve(true);
  //       img.onerror = () => resolve(false);
  //     });
  //   };

  //   for (const path of imagePaths) {
  //     const isValid = await checkImage(path);
  //     if (isValid) {
  //       return path;
  //     }
  //   }

  //   return imgOther;
  // };

  const fetchData = useCallback(
    async (page: number) => {
      try {
        setLoading(true);
        setShowNotFound(false);

        const products = await searchProduct(page, 12, name!);
        setProductData(products);

        if (products?.items?.length === 0) {
          setTimeout(() => {
            setShowNotFound(true);
          }, 1500);
        }

        // Load images for each product asynchronously
        // const imagePromises = products?.items?.map(async (product: any) => {
        //   const imagePath = await getProductImagePath(product?.code);
        //   return { code: product?.code, imagePath };
        // });

        // const loadedImages = await Promise.all(imagePromises);

        // const imageMap = loadedImages.reduce((acc: any, curr: any) => {
        //   acc[curr.code] = curr.imagePath;
        //   return acc;
        // }, {});

        // setProductImages(imageMap);

        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setLoading(false);
        setTimeout(() => {
          setShowNotFound(true);
        }, 1500);
      }
    },
    [name]
  );

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, fetchData]);
  const [currentDropdown, setCurrentDropdown] = useState(null);

  const toggleDropdown = (id: any) => {
    setCurrentDropdown(currentDropdown === id ? null : id);
  };
  const navigate = useNavigate();
  const handleProductClick = (code: string, id: number) => {
    if (!code || !id) {
      console.error("Missing data for navigation");
      return;
    }
    navigate(`/product/${code}/${id.toString()}`);
  };
  return (
    <div className="min-h-screen bg-white bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]">
      <div className="pt-[160px] w-[90vw] lg:w-[80vw] m-auto">
        <TextGenerateEffect
          words={lang === "ka" ? "პროდუქტები" : "Products"}
          className="mb-10 text-2xl lg:text-4xl"
        />
        {loading ? (
          <div className="flex justify-center items-center h-1/2">
            <span className="loading loading-ring loading-xl w-[30%] bg-[#39B085]"></span>
          </div>
        ) : productData?.items?.length > 0 ? (
          <div className="flex flex-wrap justify-between items-center gap-3 w-full">
            {productData.items.map((product: any, key: any) => (
              <div
                className="cursor-pointer"
                key={key}
                onClick={() => handleProductClick(product.code, product.id)}
              >
                <CardContainer className="inter-var">
                  <CardBody className="bg-white relative max_width group/card hover:shadow-xl hover:shadow-emerald-500/[0.1] border-gray-200 h-auto rounded-xl p-4 border flex flex-col justify-between">
                    <div>
                      <CardItem
                        translateZ="50"
                        className="text-l font-bold text-neutral-600"
                      >
                        {lang === "ka" ? product?.name_ka : product?.name_en}
                      </CardItem>

                      <CardItem
                        as="p"
                        translateZ="60"
                        className="text-neutral-500 text-sm max-w-sm mt-2"
                      >
                        {lang === "ka"
                          ? product?.description_ka
                          : product?.description_en}
                      </CardItem>
                      <CardItem
                        as="p"
                        translateZ="60"
                        className="text-neutral-500 text-sm max-w-sm mt-2"
                      >
                        {product?.code}
                      </CardItem>
                      <CardItem
                        translateZ="100"
                        className="w-full mt-4 flex justify-center"
                      >
                        <img
                          src={`https://montage.ge/img/${product?.photo}`}
                          alt={product?.name_en}
                          className="w-auto h-[200px] object-cover"
                          onError={(e) => {
                            e.currentTarget.src = imgOther;
                          }}
                        />
                      </CardItem>
                    </div>

                    <CardItem className="flex justify-between items-center w-90 py-4 ">
                      <div className="flex items-center gap-2 text-green-600 font-semibold">
                        {product?.stock ? (
                          <div className="flex items-center gap-2 text-green-600 font-semibold">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                              className="w-5 h-5"
                            >
                              <path d="M9 16.2l-3.5-3.5 1.4-1.4L9 13.4l7.1-7.1 1.4 1.4z" />
                            </svg>
                            <span>
                              {lang === "ka" ? "მარაგშია" : "In stock"}
                            </span>
                          </div>
                        ) : (
                          <div className="text-red-600 font-semibold">
                            {lang === "ka" ? "შეკვეთით" : "BY ORDER"}
                          </div>
                        )}
                      </div>
                      {product?.price !== 0 && (
                        <div className="text-lg font-bold text-gray-800">
                          ₾ {product?.price}
                        </div>
                      )}
                    </CardItem>

                    <CardItem className="rounded-xl text-sm font-bold text-[#2B2D37] cursor-pointer border-2 border-[#e5e7eb] text-center w-full">
                      {product?.pdf ||
                      product?.pdf1 ||
                      product?.pdf2 ||
                      product?.pdf3 ? (
                        <div className="relative inline-block text-left w-full">
                          <button
                            id="dropdownDefaultButton"
                            onClick={() => toggleDropdown(product.id)}
                            className="bg-white text-gray-700 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center w-full justify-between border border-gray-300"
                          >
                            {Returntext(
                              contentManagementData,
                              "data_sheet",
                              lang
                            )}
                            <svg
                              className={`w-2.5 h-2.5 ml-3 transition-transform duration-200 ${
                                currentDropdown === product.id
                                  ? "rotate-180"
                                  : ""
                              }`}
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 10 6"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 1l4 4 4-4"
                              />
                            </svg>
                          </button>

                          {currentDropdown === product.id && (
                            <div className="absolute bottom-full mb-2 bg-gray-100 divide-y divide-gray-100 rounded-lg shadow w-full z-50">
                              <ul className="py-2 text-sm text-gray-700">
                                {[
                                  {
                                    label: Returntext(
                                      contentManagementData,
                                      "pdf",
                                      lang
                                    ),
                                    url: product.pdf,
                                  },
                                  {
                                    label: Returntext(
                                      contentManagementData,
                                      "pdf1",
                                      lang
                                    ),
                                    url: product.pdf1,
                                  },
                                  {
                                    label: Returntext(
                                      contentManagementData,
                                      "pdf2",
                                      lang
                                    ),
                                    url: product.pdf2,
                                  },
                                  {
                                    label: Returntext(
                                      contentManagementData,
                                      "pdf3",
                                      lang
                                    ),
                                    url: product.pdf3,
                                  },
                                ]
                                  .filter((file) => file.url)
                                  .map((file, index) => (
                                    <li key={index}>
                                      <a
                                        href={`https://montage.ge/img/${file.url}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={() => setCurrentDropdown(null)}
                                        className="block px-4 py-2 text-gray-700 hover:bg-gray-200 "
                                      >
                                        {file.label}
                                      </a>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      ) : (
                        // "No PDF available" styled similarly to the dropdown button
                        <button
                          disabled
                          className="bg-white text-gray-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center w-full justify-between bordercursor-not-allowed"
                        >
                          {Returntext(
                            contentManagementData,
                            "no_pdf_available",
                            lang
                          )}
                        </button>
                      )}
                    </CardItem>
                  </CardBody>
                </CardContainer>
              </div>
            ))}
          </div>
        ) : (
          showNotFound && (
            <div className="flex justify-center items-center mt-10">
              <img
                src={notFoundIcon}
                alt="card"
                width={1440}
                height={740}
                className="w-full lg:w-[60%] object-cover"
              />
            </div>
          )
        )}
        <div className="flex justify-center items-center mt-4">
          {productData?.meta?.totalPages > 1 && (
            <CircularPagination
              activePage={currentPage}
              setActivePage={setCurrentPage}
              totalPages={productData?.meta?.totalPages}
            />
          )}
        </div>
      </div>
    </div>
  );
}
